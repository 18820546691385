export const DISPLAY_BOGO = false
export const FRAME_VARIANTS = ['Black', 'Silver', 'White']
export const LIMITED_EDITION_VARIANTS = ['Gold', 'Poppy']

export const BUY_WITH_PRIME_SKU = {
  10: { Black: 'SKY-V3', White: '100DB', Silver: '100DC', Gold: '100DF', Poppy: '' },
  15: { Black: '150-FRA' },
}
export const BUY_WITH_PRIME_SKU_VARIANT_SKUS = [
  '150-FRA',
  '100-FRM',
  '100DB',
  '100DC',
  '100DF',
  'SKY-V3',
]
export const AMAZON_LINKS = {
  en: {
    10: {
      Black: 'https://urlgeni.us/amazon/shopify-10inch-black-us',
      White: 'https://urlgeni.us/amazon/shopify-10inch-white-us',
      Silver: 'https://urlgeni.us/amazon/shopify-10inch-silver-us',
      Gold: 'https://urlgeni.us/amazon/shopify-10inch-gold-us',
    },
    15: {
      Black: 'https://urlgeni.us/amazon/shopify-15inch-black-us',
    },
    calendar:
      'https://www.amazon.com/Skylight-Calendar-Touchscreen-Interactive-Schedules/dp/B0C9V811L6?maas=maas_adg_16B76AF3EC9714D34FE1E6F753F7A2A5_afap_abs&ref_=aa_maas&tag=maas',
  },
  'en-ca': {
    10: {
      Black: 'https://urlgeni.us/amazon/shopify-10in-black-ca',
    },
    15: {
      Black: 'https://urlgeni.us/amazon/shopify-15inch-black-ca',
    },
  },
  'en-gb': {
    10: {
      Black: 'https://urlgeni.us/amazon/shopify-10inch-black-uk',
    },
    15: {
      Black: 'https://urlgeni.us/amazon/shopify-15inch-black-uk',
    },
  },
  de: {
    10: {
      Black: 'https://urlgeni.us/amazon/shopify-10inch-black-de',
    },
    15: {
      Black: 'https://urlgeni.us/amazon/shopify-15inch-black-de',
    },
  },
  'en-au': {
    10: {
      Black:
        'https://www.amazon.com.au/Skylight-Frame-Digital-Picture-Anywhere/dp/B01N7ENHO6/ref=s[…]%2Caps%2C79&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1',
    },
    15: {
      Black:
        'https://www.amazon.com.au/Skylight-Frame-Digital-Picture-Anywhere/dp/B0BHX94SSQ/ref=s[…]%2Caps%2C79&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1',
    },
  },
}
