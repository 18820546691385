import React, { useContext, useEffect, useState } from 'react'

import theme from 'src/utils/styles/theme'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Analytics from 'src/utils/Analytics'
import { AMAZON_LINKS } from '../FrameProductForm/constants'
import StoreContext from '../../context/StoreContext'
import { Container, Text, CalLink, ALink } from './styles'
import {
  IS_FOREIGN_COUNTRY,
  LOCALIZATION,
  BUY_LINK,
  CAL_BUY_LINK,
  IN_AU,
  IN_CA,
  IN_UK,
  FRAME_2_BLACK_BUY_LINK,
} from '../../utils/constants'
import { bannerHTMLFormatter } from './utils'

const extractValues = (data) => {
  const bannerCopy = {}
  Object.keys(data).forEach((key) => {
    let formattedKey = key
    if (key.includes('_')) {
      formattedKey = key.replace('_', '-')
    }
    bannerCopy[formattedKey] = data[key]
  })

  return bannerCopy
}

function getUtmParameter(paramName) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(paramName)
}

const Banner = ({ page }) => {
  const { banner } = useStaticQuery(graphql`
    query BannerQuery {
      banner: allContentfulBanner {
        edges {
          node {
            es
            fr
            it
            ie
            nl
            de
            en
            en_calendar
            en_au
            en_ca
            en_gb
            en_eu
          }
        }
      }
    }
  `)
  const [isCal, setIsCal] = useState(false)
  const BANNER_COPY_OVERRIDE = extractValues(banner.edges[0].node)
  const [showBanner, setShowBanner] = useState(false)
  const [discountApplied, setDiscountApplied] = useState(false)

  const {
    store: {
      cart,
      banner: { promoCode, channel, amount },
    },
    applyDiscount,
    removeDiscount,
    updateBanner,
  } = useContext(StoreContext)

  const { id } = cart
  const discountCode = cart?.discountCodes?.[0]?.code || ''

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBanner(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!id || !promoCode || discountApplied) return

    const applyOrUpdateDiscount = async () => {
      try {
        // if (discountCode && discountCode.toUpperCase() !== promoCode.toUpperCase()) {
        //   await removeDiscount(id)
        // }

        if (discountCode !== promoCode) {
          await applyDiscount(promoCode)
          setDiscountApplied(true) // Mark as applied
        }
      } catch (error) {
        console.error('Error while applying or removing discount:', error)
      }
    }

    applyOrUpdateDiscount()
  }, [id, promoCode, discountCode, applyDiscount, removeDiscount, discountApplied])

  // Additional useEffect to reset discountApplied state when promoCode changes
  useEffect(() => {
    setDiscountApplied(false)
  }, [promoCode])

  useEffect(() => {
    const source = getUtmParameter('utm_source')
    const utm_campaign = getUtmParameter('utm_campaign')
    if (source === 'babylist') {
      updateBanner('BABYLIST', 'email', '25%')
    }
    if (source === 'homeedit') {
      updateBanner('HOMEEDIT', 'email', '20%')
    }
    if (utm_campaign === 'cheer_video' || utm_campaign === 'cheer_banner') {
      updateBanner('CHEER', 'PodcastFrame', '$15')
    }
    const currentUrl = window.location.href
    const isCalendarPage = currentUrl.includes('calendar')
    setIsCal(isCalendarPage)
  }, [])

  const bgColor = theme.blueDark
  const MARKETING_BANNER_OVERRIDES = {
    Toast: `Thanks for listening! Use Code ${promoCode} for ${amount} off!`,
    Listening: `Thanks for listening! Use code ${promoCode} for ${amount} off your Calendar purchase!`,
    Influencer: `We're so glad you're here! Please use ${promoCode} for ${amount} off your 15" Calendar purchase.`,
    InfluencerMax: `We're so glad you're here! Please use ${promoCode} for ${amount} off your 27" Cal Max purchase.`,
    Today: `We're so glad you're here! Please use ${promoCode} for ${amount} off your 10" Frame purchase.`,
    InfluencerFrame: `We're so glad you're here! Please use ${promoCode} for ${amount} off your frame purchase.`,
    PodcastFrame: `Thanks for listening! Use code ${promoCode} for ${amount} off your Frame purchase!`,
    PodcastBoth: `Thanks for listening! Use code ${promoCode} for ${amount} off!`,
    GMA: `We're so glad you're here! Please use GMA for $50 off your 10" or 15" Calendar purchase.`,
    DirectMail: `Use code ${promoCode} for ${amount} off 10" and 15" Calendars!`,
    PR: `We're so glad you're here. Use ${promoCode} for ${amount} off your 10" or 15" Calendar purchase.`,
    TVFrame: `Use code TV20 FOR $20 OFF your Frame at checkout`,
    TVCal: `${
      IS_FOREIGN_COUNTRY
        ? 'Use code TV FOR 20% OFF your Frame at checkout'
        : 'Use code TV15 FOR 15% OFF your Calendar at checkout'
    }`,
  }

  const discountMessages = {
    BABYLIST:
      'Cyber Week Sale ✨ Save $30 off select Skylight Frames. BABYLIST discount code automatically applied at checkout.',
    CHEER: 'Take $15 off your purchase with the code CHEER',
    HOMEEDIT:
      'Enjoy 20% off your Skylight Calendar purchase. Discount automatically applied at checkout.',
    CASEFILE: `Thanks for listening! Use code CASEFILE for ${
      IN_AU ? '20%' : '15%'
    } off your Frame purchase!`,
    MFM: `Thanks for listening! Use code MFM for ${
      IN_AU || IN_CA ? '20%' : '15%'
    } off your Frame purchase!`,
    LOVE: `Thanks for listening! Use code LOVE for ${
      IN_CA ? '20%' : '$20'
    } off your Frame purchase!`,
    FUN: `Thanks for listening! Use code FUN for ${IN_CA ? '20%' : '$20'} off your Frame purchase!`,
  }

  if (discountMessages[promoCode]) {
    return (
      <Container bgColor={bgColor} className="banner">
        <Text>{showBanner && <>{discountMessages[promoCode]}</>}</Text>
      </Container>
    )
  }

  if (promoCode) {
    return (
      <Container bgColor="#D6D5E7" className="banner">
        <Text textcolor="#043A3C">
          {showBanner && (
            <>{MARKETING_BANNER_OVERRIDES[channel] || bannerHTMLFormatter(promoCode, amount)}</>
          )}
        </Text>
      </Container>
    )
  }
  let bannerText = ''
  if (isCal && !IS_FOREIGN_COUNTRY && BANNER_COPY_OVERRIDE['en-calendar'] !== 'NONE') {
    bannerText = BANNER_COPY_OVERRIDE['en-calendar']
  } else if (BANNER_COPY_OVERRIDE[LOCALIZATION] !== 'NONE') {
    bannerText = BANNER_COPY_OVERRIDE[LOCALIZATION]
  }
  if (!IS_FOREIGN_COUNTRY) {
    return (
      <Container className="banner">
        <Text textcolor="#fff">
          ⚡ Bundle & save up to 50% on <CalLink to={BUY_LINK}>Frames</CalLink> only for a limited
          time ⚡
        </Text>
      </Container>
    )
  }

  if (bannerText) {
    return (
      <Container bgColor={bgColor} className="banner">
        <Text>{bannerText}</Text>
      </Container>
    )
  }

  return null
}
Banner.propTypes = {
  page: PropTypes.string,
}

export default Banner
